<template>
    <main>
        <canvas id="graficoImc" class="canvas-h canvas-w"></canvas>
        <div class="form-group">
            <span class="font-weight-bolder">Observaciones </span>
            <textarea class="form-control mt-3"  cols="10" rows="5" v-model="observaciones"></textarea>
        </div>
    </main>
</template>
  
<script>
import Chart from 'chart.js/auto';
import chartData from '../../../../assets/datasheets/charts/imcGestantes.json';
import embarazoService from "../../../../services/embarazoService";
import embarazoActualService from "../../../../services/embarazoActualService";
import dayjs from 'dayjs';
export default {
    props: ['x', 'y', 'data_imc','idUsuario'],
    data() {
        return {
            embarazo_actual:{},
            observaciones:''
        }
    },
    methods: {
        open() {

            
            let data_intersect=[];
            this.data_imc.forEach((item) => {
                let dataPoint = {
                    x: this.semanas_gestacion(item.fecha),
                    y: this.indice_masa_corporal(item.peso, item.talla)
                };
                data_intersect.push(dataPoint);
            });

            const labels = chartData.datapoints;
            const datasets = [];

            chartData.series.forEach(s => {
                datasets.push({
                    type: 'line',
                    label: s.title,
                    data: s.data,
                    borderColor: s.color,
                    backgroundColor: s.backgroundColor,
                    fill: true,
                    tension: 0.5,
                    borderWidth: 1.5,
                });
            });

            datasets.push({
                type: 'line',
                backgroundColor: 'rgba(75, 00, 150,1)',
                borderColor: 'rgba(75, 00, 150,1)',
                fill: false,
                label: 'Imc',
                data: data_intersect,
                options: {
                    scales: {
                        x: {
                            type: 'linear',
                            position: 'bottom',
                        }
                    },
                },
                elements: {
                    point: {
                        radius: 4
                    }
                }
            });

            const data = {
                labels: labels,
                datasets: datasets
            };

            const config = {
                data: data,
                options: {
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Evaluación Nutricional Gestantes Según IMC' 
                        },
                    },
                    interaction: {
                        intersect: false,
                    },
                    scales: {
                        x: {
                            display: true,
                            title: {
                                display: true,
                                text: 'Semanas de Gestación'
                            },
                            ticks: {
                                callback: function(value) {
                                    if(Number(this.getLabelForValue(value)) % 5 === 0){
                                        return `${this.getLabelForValue(value)}`;
                                    }
                                }
                            },
                        },
                        y: {
                            display: true,
                            title: {
                                display: true,
                                text: 'Imc'
                            },
                            suggestedMin: 15,
                        }
                    },
                    elements: {
                        point: {
                            radius: 0
                        }
                    }
                },
            };

            const ctx = document.getElementById('graficoImc').getContext('2d');

            new Chart(ctx, config);
        },
        export() {
            const canva = document.getElementById('graficoImc');
            const urlData = canva.toDataURL('image/png', 0.8);
            return {
                chart: 'Evaluacion nutricional gestantes',
                imageData: urlData,
                observaciones: this.observaciones,
                codigo:'eva_nut'
            };
        },
        indice_masa_corporal(peso, talla) {
            if (peso !== '' && talla !== '') {
                let imc = Number(peso) / (Math.pow(Number(talla / 100), 2));
                return imc.toFixed(2);
            }
            return '';
        },
        semanas_gestacion(fecha) {
            const lastMenstruationDate = dayjs(this.embarazo_actual.ultima_menstruacion);
            const currentDate = dayjs(fecha);
            return currentDate.diff(lastMenstruationDate, 'week');
        }
    },
    watch: {
        data_imc: {
            handler: async function () {
                const res_embarazo_actual= await embarazoService.showByLast(this.idUsuario);
                const response=await embarazoActualService.showByLast(res_embarazo_actual.data.id);
                this.embarazo_actual=response.data;
                this.$nextTick(() => this.open());
            },
            deep: true
        },
    },
    async created() {
        const res_embarazo_actual= await embarazoService.showByLast(this.idUsuario);
        const response=await embarazoActualService.showByLast(res_embarazo_actual.data.id);
        this.embarazo_actual=response.data;
        //this.$nextTick(() => this.open());
    }
}

</script>
  
<style scoped>
.canvas-h {
    height: 100%;
}

.canvas-w {
    width: 100%;
}
</style>